const backresourdata = {
    // 后台管理--基础数据管理--资源类型删除/批量删除
    deleteResourceType: {
        method: 'post',
        url: '/backage/resourceType/deleteResourceType',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理--基础数据管理--资源类型添加 
    insertResourceType: {
        method: 'post',
        url: '/backage/resourceType/insertResourceType',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--资源类型查询
    backqueryResourceType: {
        method: 'post',
        url: '/backage/resourceType/queryResourceType',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--资源类型编辑的查询
    queryTypeById: {
        method: 'post',
        url: '/backage/resourceType/queryTypeById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--资源类型修改
    updateResourceType: {
        method: 'post',
        url: '/backage/resourceType/updateResourceType',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理--基础数据管理--资源类型禁用/批量禁用
    updateTypeDisable: {
        method: 'post',
        url: '/backage/resourceType/updateTypeDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--资源类型启用/批量启用
    updateTypeEnable: {
        method: 'post',
        url: '/backage/resourceType/updateTypeEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
}
export {
    backresourdata
}