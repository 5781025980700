import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  //重定向
  {
    path: '/',
    redirect: 'home'
  },
  //首页
  {
    path: '/index',
    name: 'index',
    component: () => import('../views/index.vue'),
    children: [

      //首页
      {
        path: '/home',
        name: '1',
        component: () => import('../views/home/home.vue'),
        meta: {
          curId: 1,
          title: '首页'
        },
      },

      // ---
      // 教研圈
      {
        path: '/circle',
        name: '2',
        component: () => import('../views/circle/circle.vue'),
        meta: {
          curId: 2,
          title: '教研圈'
        },
      },
      // 教研圈介绍
      {
        path: '/circleintroduce',
        name: 'circleintroduce',
        component: () => import('../views/circle/components/circleintroduce.vue'),
        meta: {
          curId: 2,
          title: '教研圈'
        },
      },
      // 教研圈公告
      {
        path: '/circlenotice',
        name: 'circlenotice',
        component: () => import('../views/circle/components/circlenotice.vue'),
        meta: {
          curId: 2,
          title: '教研圈'
        },
      },

      // ---
      // 课程案例
      {
        path: '/course',
        name: '3',
        component: () => import('../views/course/course.vue'),
        meta: {
          curId: 3,
          title: '课程案例'
        },
      },
      // 课程介绍
      {
        path: '/introduce',
        name: 'introduce',
        component: () => import('../views/course/components/introduce.vue'),
        meta: {
          curId: 3,
          title: '课程案例'
        },
      },
      // 课程详情
      {
        path: '/details',
        name: 'details',
        component: () => import('../views/course/components/details.vue'),
        meta: {
          curId: 3,
          title: '课程案例'
        },
      },
      // 系列课程
      {
        path: '/series',
        name: 'series',
        component: () => import('../views/course/components/series.vue'),
        meta: {
          curId: 3,
          title: '课程案例'
        },
      },



      // ---
      // 资源资料
      {
        path: '/resources',
        name: '4',
        component: () => import('../views/resources/resources.vue'),
        meta: {
          curId: 4,
          title: '资源资料'
        },
      },
      // 
      {
        path: '/resourcesinfo',
        name: 'resourcesinfo',
        component: () => import('../views/resources/components/resourcesinfo.vue'),
        meta: {
          curId: 4,
          title: '资源资料'
        },
      },


      // ---
      //通知公告
      {
        path: '/activity',
        name: '5',
        component: () => import('../views/activity/activity.vue'),
        meta: {
          curId: 5,
          title: '通知公告'
        },
      },
      {
        path: '/activityintroduce',
        name: 'activityintroduce',
        component: () => import('../views/activity/components/activityintroduce.vue'),
        meta: {
          curId: 5,
          title: '通知公告'
        },
      },
      {
        path: '/showactive',
        name: 'showactive',
        component: () => import('../views/activity/components/showactive.vue'),
        meta: {
          curId: 5,
          title: '通知公告'
        },
      },


      // ---
      // 特色项目
      {
        path: '/features',
        name: '6',
        component: () => import('../views/features/features.vue'),
        meta: {
          curId: 6,
          title: '特色项目'
        },
      },
      {
        path: '/featuresinfo',
        name: '6',
        component: () => import('../views/features/featuresinfo.vue'),
        meta: {
          curId: 6,
          title: '特色项目'
        },
      },


      // ---
      // 成果推广
      {
        path: '/diffusion',
        name: '7',
        component: () => import('../views/diffusion/diffusion.vue'),
        meta: {
          curId: 7,
          title: '成果推广'
        },
      },
      {
        path: '/diffusioninfo',
        name: '7',
        component: () => import('../views/diffusion/diffusioninfo.vue'),
        meta: {
          curId: 7,
          title: '成果推广'
        },
      },


      // ---
      // 党建品牌
      {
        path: '/brand',
        name: '8',
        component: () => import('../views/brand/brand.vue'),
        meta: {
          curId: 8,
          title: '党建品牌'
        },
      },
      // 党建品牌
      {
        path: '/brandinfo',
        name: 'brandinfo',
        component: () => import('../views/brand/brandinfo.vue'),
        meta: {
          curId: 8,
          title: '党建品牌'
        },
      },



      // ---
      //个人中心
      {
        path: '/userinfo',
        name: '9',
        component: () => import('../views/userinfo/userinfo.vue'),
        meta: {
          curId: 9,
          title: '个人中心'
        },
        children: [
          // 我的课程
          {
            path: '/myclass',
            name: '11-1',
            component: () => import('../views/userinfo/components/class/myclass.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 上传课程
          {
            path: '/upclass',
            name: 'upclass',
            component: () => import('../views/userinfo/components/class/upclass.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 课程收藏
          {
            path: '/mycollection',
            name: '11-2',
            component: () => import('../views/userinfo/components/class/mycollection.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 课程审核
          {
            path: '/myaudit',
            name: '11-3',
            component: () => import('../views/userinfo/components/class/myaudit.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 系列课程
          {
            path: '/myseries',
            name: '11-4',
            component: () => import('../views/userinfo/components/class/myseries.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 上传系列课程
          {
            path: '/upseries',
            name: 'upseries',
            component: () => import('../views/userinfo/components/class/upseries.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },


          // 资源上传
          {
            path: '/resource',
            name: '12-1',
            component: () => import('../views/userinfo/components/resource/resource.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 上传资源
          {
            path: '/uploadresource',
            name: 'uploadresource',
            component: () => import('../views/userinfo/components/resource/uploadresource.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 资源管理
          {
            path: '/resourcemange',
            name: '12-2',
            component: () => import('../views/userinfo/components/resource/resourcemange.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },


          // 建立的圈子
          {
            path: '/circlemanage',
            name: '13-1',
            component: () => import('../views/userinfo/components/circle/circle.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 建立新圈子 
          {
            path: '/createcircle',
            name: 'createcircle',
            component: () => import('../views/userinfo/components/circle/createcircle.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 加入的圈子
          {
            path: '/joincircle',
            name: '13-2',
            component: () => import('../views/userinfo/components/circle/joincircle.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },
          // 我的网盘
          // {
          //   path: '/circleaudit',
          //   name: '13-3',
          //   component: () => import('../views/userinfo/components/circle/circleaudit.vue'),
          //   meta: {
          //     curId: 9,
          //     title: '个人中心'
          //   },
          // },
          // 圈子审核
          {
            path: '/circleaudit',
            name: '13-4',
            component: () => import('../views/userinfo/components/circle/circleaudit.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },

          // 个人信息
          {
            path: '/personinfo',
            name: '18',
            component: () => import('../views/userinfo/components/personinfo/personinfo.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          },


          // 我的收藏
          {
            path: '/circleaudit',
            name: '19-1',
            component: () => import('../views/userinfo/components/collection/collection.vue'),
            meta: {
              curId: 9,
              title: '个人中心'
            },
          }
        ]
      },
      // 新闻动态
      {
        path: '/newstrend',
        name: '10',
        component: () => import('../views/news/newstrend.vue'),
        meta: {
          curId: 10,
          title: '新闻动态'
        },
      },
      {
        path: '/newinfo',
        name: '10',
        component: () => import('../views/news/newinfo.vue'),
        meta: {
          curId: 10,
          title: '新闻动态'
        },
      },

      // ---
      // 查看文件
      // 查看课程
      // {
      //   path: '/viewclass',
      //   name: 'viewclass',
      //   component: () => import('../views/viewfile/viewclass.vue'),
      //   meta: {
      //     curId: 11,
      //     title: '查看课程'
      //   },
      // },
      // {
      //   path: '/viewdetails',
      //   name: 'viewdetails',
      //   component: () => import('../views/viewfile/viewdetails.vue'),
      //   meta: {
      //     curId: 11,
      //     title: '查看课程'
      //   },
      // },
      // 查看
    ]
  },
  {
    path: '/backsystem',
    name: 'backsystem',
    component: () => import('../views/backsystem/index.vue'),
  }

]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router