const backperioddata = {
    // 后台管理--基础数据管理--学段删除
    deletePeriod: {
        method: 'post',
        url: '/backage/period/deletePeriod',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段管理查询
    queryPeriodBackage: {
        method: 'post',
        url: '/backage/period/queryPeriodBackage',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段编辑查询
    queryPeriodById: {
        method: 'post',
        url: '/backage/period/queryPeriodById',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段----添加学段
    savePeriod: {
        method: 'post',
        url: '/backage/period/savePeriod',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段----批量禁用/禁用
    updateDisable: {
        method: 'post',
        url: '/backage/period/updateDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段----批量启用/启用
    updateEnable: {
        method: 'post',
        url: '/backage/period/updateEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学段编辑操作
    updatePeriod: {
        method: 'post',
        url: '/backage/period/updatePeriod',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
}
export {
    backperioddata
}