const backlabeldata = {
    //后台管理--基础数据管理--系列标签--删除/批量删除 
    deleteLabel: {
        method: 'post',
        url: '/backage/seriseLabel/deleteLabel',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--系列标签--查询标签
    querySeriseLabel: {
        method: 'post',
        url: '/backage/seriseLabel/querySeriseLabel',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理--基础数据管理--系列标签--编辑查询 
    querySeriseLabelById: {
        method: 'post',
        url: '/backage/seriseLabel/querySeriseLabelById',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--系列标签--新建
    saveSeriseLabel: {
        method: 'post',
        url: '/backage/seriseLabel/saveSeriseLabel',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--系列标签--禁用/批量禁用
    updatelabelDisable: {
        method: 'post',
        url: '/backage/seriseLabel/updateDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--系列标签--启用/批量启用
    updatelabelEnable: {
        method: 'post',
        url: '/backage/seriseLabel/updateEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--系列标签--编辑
    updateSeriseLabel: {
        method: 'post',
        url: '/backage/seriseLabel/updateSeriseLabel',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
}
export {
    backlabeldata
}