const sensitivedata = {
    // 后台管理--系统管理--敏感词管理--删除/批量删除敏感词
    deleteSenstitive: {
        method: 'post',
        url: '/backage/sensitive/deleteSenstitive',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--敏感词管理--查询敏感词
    querySensitive: {
        method: 'post',
        url: '/backage/sensitive/querySensitive',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--敏感词管理--编辑时查询敏感词
    querySensitiveById: {
        method: 'post',
        url: '/backage/sensitive/querySensitiveById',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理--系统管理--敏感词管理--添加敏感词 
    saveSensitive: {
        method: 'post',
        url: '/backage/sensitive/saveSensitive',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--敏感词管理--禁用/批量禁用
    updatesensDisable: {
        method: 'post',
        url: '/backage/sensitive/updateDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--敏感词管理--启用/批量启用
    updatesensEnable: {
        method: 'post',
        url: '/backage/sensitive/updateEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--敏感词管理--编辑
    updateSensititve: {
        method: 'post',
        url: '/backage/sensitive/updateSensititve',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
}
export {
    sensitivedata
}