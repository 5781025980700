const backroledata = {
    // 后台管理--系统管理--角色管理--授权
    authOrize: {
        method: 'post',
        url: '/backage/role/authOrize',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--删除角色/批量删除
    deleteRole: {
        method: 'post',
        url: '/backage/role/deleteRole',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--查询后台管理的所有的权限（查询只有两级的菜单 三级菜单不支持）
    queryAllBackageMen: {
        method: 'post',
        url: '/backage/role/queryAllBackageMen',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 后台管理--系统管理--角色管理--查询后台的权限（可以查询多级菜单）
    queryAllBackageMenBurst: {
        method: 'post',
        url: '/backage/role/queryAllBackageMenBurst',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--查询门户所有的权限（查询只有两级的菜单 三级菜单不支持）
    queryAllPersonMen: {
        method: 'post',
        url: '/backage/role/queryAllPersonMen',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 后台管理--系统管理--角色管理--查询门户的权限（可以查询多级菜单）
    queryPersonMenBurst: {
        method: 'post',
        url: '/backage/role/queryPersonMenBurst',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    //后台管理--系统管理--角色管理--查询角色
    queryRole: {
        method: 'post',
        url: 'backage/role/queryRole',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--编辑查询通过id
    queryRoleById: {
        method: 'post',
        url: '/backage/role/queryRoleById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 后台管理--系统管理--角色管理--添加角色
    saveRole: {
        method: 'post',
        url: '/backage/role/saveRole',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--修改角色
    updateRole: {
        method: 'post',
        url: '/backage/role/updateRole',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--禁用/批量禁用
    updateRoleStatusDisable: {
        method: 'post',
        url: '/backage/role/updateRoleStatusDisable',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--角色管理--启用/批量启用
    updateRoleStatusEnable: {
        method: 'post',
        url: '/backage/role/updateRoleStatusEnable',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
}
export {
    backroledata
}