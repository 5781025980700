import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//引入 element
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI)

//引入echarts
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts

// 引入图片处理
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

// 引入axios
import axios from 'axios'
Vue.prototype.$axios = axios

//引入 api
import http from '@/request/http'
Vue.prototype.$Http = http;
// 引入md5
import md5 from 'js-md5'
Vue.prototype.$md5 = md5;

// cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

// 
import {Base64} from 'js-base64'
Vue.prototype.$Base64 = Base64;




Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')