const backsubjectdata = {
    //后台管理--基础数据管理--学科管理--删除/批量删除 
    deleteSubject: {
        method: 'post',
        url: '/backage/subject/deleteSubject',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--查询学科
    backquerySubject: {
        method: 'post',
        url: '/backage/subject/querySubject',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--编辑查询
    querySubjectById: {
        method: 'post',
        url: '/backage/subject/querySubjectById',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--添加学科
    saveSubject: {
        method: 'post',
        url: '/backage/subject/saveSubject',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--禁用/批量禁用
    updatesubDisable: {
        method: 'post',
        url: '/backage/subject/updateDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--启用/批量启用
    updatesubEnable: {
        method: 'post',
        url: '/backage/subject/updateEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--学科管理--修改
    updateSubject: {
        method: 'post',
        url: '/backage/subject/updateSubject',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
}
export {
    backsubjectdata
}