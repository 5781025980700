const recomclassdata = {
    //后台管理--课程管理/推荐课程--普通课程---取消推荐课程/批量取消推荐课程 
    deleteRecommendCourse: {
        method: 'post',
        url: '/backage/course/deleteRecommendCourse',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--课程管理/推荐课程--普通课程---进行排序
    modifyLocation: {
        method: 'post',
        url: '/backage/course/modifyLocation',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--课程管理/全部课程--普通课程
    queryCourse: {
        method: 'post',
        url: '/backage/course/queryCourse',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--课程管理/推荐课程--查询课程
    queryRecommedCourse: {
        method: 'post',
        url: '/backage/course/queryRecommedCourse',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--课程管理/全部课程--普通课程---推荐课程/批量推荐
    recommendCourse: {
        method: 'post',
        url: '/backage/course/recommendCourse',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
}
export {
    recomclassdata
}