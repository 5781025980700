const userInfo = {
  namespaced: true, //开启命名空间
  state: {
    // 个人中心的面包屑
    breadname: null,
    // 接收编辑课程上传的内容
    classRawId: null,
    // 教研圈发布公告后刷新公告列表
    notiType: false,
    // 公告 清空富文本
    editors: false,
  },
  mutations: {
    // 编辑面包屑
    editbreadname(state, name) {
      state.breadname = name
    },
    // 
    editclassRaw(state, raw) {
      state.classRawId = raw
    },
    // 刷新通知公告
    postnotice(state, status) {
      state.notiType = status
    },
    // 发布公告
    editoras(state, status) {
      state.editors = status
    }
  },
  actions: {},
  //包装state
  getters: {},
}
export default userInfo