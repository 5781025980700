const resourdata = {
    //获取门户-课程管理-查询资料
    queryResourceList: {
        method: 'post',
        url: 'course/homePage/queryResourceList',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询资料详情
    queryResourceDetails: {
        method: 'post',
        url: 'resource/homePage/queryResourceDetail',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 资源资料发布评论
    releaseComments: {
        method: 'post',
        url: '/resource/releaseComment',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // ---
    // 个人中心

    //个人中心-资源上传-删除课程/批量删除
    deleteResource: {
        method: 'post',
        url: '/resource/deleteResource',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //个人中心-资源管理-上传资源-编辑资源时的查询
    queryResorceDetailById: {
        method: 'post',
        url: '/resource/queryResorceDetailById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    //个人中心-资源管理-上传资源-查询资源列表
    queryResource: {
        method: 'post',
        url: '/resource/queryResource',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },

    //个人中心-资源管理-上传资源(添加/修改)
    saveResource: {
        method: 'post',
        url: '/resource/saveResource',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //个人中心-资源管理-资源审核-查询资源列表
    queryAuditResource: {
        method: 'post',
        url: '/resource/queryAuditResource',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //个人中心-资源上传-提交审核/批量提交审核
    updateAudit: {
        method: 'post',
        url: '/resource/updateAudit',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //个人中心-资源审核-审核通过（批量）/审核不通过（批量）
    auditCourse: {
        method: 'post',
        url: '/resource/homePage/auditCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },


}
export {
    resourdata
}