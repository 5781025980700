const circledata = {
    // 教研圈
    // 教研圈展示页
    queryCircle: {
        method: 'post',
        url: '/homepage/circle/queryCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 首页教研圈--查询教研圈详情
    queryCircleDetailById: {
        method: 'post',
        url: '/circle/queryCircleDetailById',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 教研圈介绍页---删除回复内容
    deleteReply: {
        method: 'post',
        url: '/circle/talk/deleteReply',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈介绍页---删除话题
    deleteTalk: {
        method: 'post',
        url: '/circle/talk/deleteTalk',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 教研圈介绍页---查询话题
    queryCircleTalk: {
        method: 'post',
        url: '/circle/talk/queryCircleTalk',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈介绍页---查询话题下的回复内容
    queryCircleTalkReply: {
        method: 'post',
        url: '/circle/talk/queryCircleTalkReply',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈介绍页---对话题进行评论 查询话题的详细信息
    queryTalkDetailById: {
        method: 'post',
        url: '/circle/talk/queryTalkDetailById',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },


    // 教研圈介绍页---发布话题
    saveCircleTalk: {
        method: 'post',
        url: '/circle/talk/saveCircleTalk',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈介绍页---发布话题
    saveTailkReply: {
        method: 'post',
        url: '/circle/talk/saveTailkReply',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },


    // ------
    // 个人中心--查询我发布的话题
    queryMyReleaseTalk: {
        method: 'post',
        url: '/circle/talk/queryMyReleaseTalk',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心--查询我回复的话题
    queryMyReplyTalk: {
        method: 'post',
        url: '/circle/talk/queryMyReplyTalk',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 个人中心----教研圈
    // 个人中心-建立的圈子-删除圈子/批量删除圈子
    deleteCricle: {
        method: 'post',
        url: '/personCenter/deleteCricle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-建立的圈子-查询圈子的信息
    queryCricleById: {
        method: 'post',
        url: '/personCenter/queryCricleById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 个人中心-建立的圈子-查询未上线的圈子
    queryMyNoOnlineCircle: {
        method: 'post',
        url: '/personCenter/queryMyNoOnlineCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-建立的圈子-查询上线的圈子
    queryMyOnlineCircle: {
        method: 'post',
        url: '/personCenter/queryMyOnlineCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-建立的圈子-添加圈子
    saveCricle: {
        method: 'post',
        url: '/personCenter/saveCricle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-建立的圈子-编辑圈子
    updateCircle: {
        method: 'post',
        url: '/personCenter/updateCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-建立的圈子-提交审核/批量提交审核
    updateSaveCircleAudit: {
        method: 'post',
        url: '/personCenter/updateSaveCircleAudit',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },



    // 个人中心-圈子审核-查询圈子
    queryCircles: {
        method: 'post',
        url: '/audit/circle/queryCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 

    // 
    // 个人中心-圈子审核-审核通过/批量审核通过
    updateCircleApproved: {
        method: 'post',
        url: '/audit/circle/updateCircleApproved',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-圈子审核-审核不通过/批量审核不通过
    updateCircleNoApproved: {
        method: 'post',
        url: '/audit/circle/updateCircleNoApproved',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 个人中心-加入的圈子-查询圈子
    queryMyEnterCircle: {
        method: 'post',
        url: '/enter/circle/queryMyEnterCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // ---
    // 个人中心---建立的圈子---加入圈子
    addCircleMember: {
        method: 'post',
        url: '/circle/addCircleMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---不同意加入圈子
    addNoCircleMember: {
        method: 'post',
        url: '/circle/addNoCircleMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---移除圈子
    deleteCircleMember: {
        method: 'post',
        url: '/circle/deleteCircleMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---删除公告
    deleteNotice: {
        method: 'post',
        url: '/circle/deleteNotice',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 申请加入圈子
    enterCircle: {
        method: 'post',
        url: '/circle/enterCircle',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 个人中心---建立的圈子---查询圈子的已加入人员信息
    queryCircleEnterMember: {
        method: 'post',
        url: '/circle/queryCircleEnterMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询圈子下的成员信息
    queryCircleMember: {
        method: 'post',
        url: '/circle/queryCircleMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---查询圈子的未加入人员信息
    queryCircleNoEnterMember: {
        method: 'post',
        url: '/circle/queryCircleNoEnterMember',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---查询公告
    queryCircleNotic: {
        method: 'post',
        url: '/circle/queryCircleNotic',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---查询单个公告/首页教研圈--查询单个公告
    queryOneNotice: {
        method: 'post',
        url: '/circle/queryOneNotice',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },


    // 个人中心---建立的圈子---已加入圈子成员---根据用户名查询用户
    queryUserMsgByNameEnterCircle: {
        method: 'post',
        url: '/circle/queryUserMsgByNameEnterCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---未加入圈子成员---根据用户名查询用户
    queryUserMsgByNameNoEnterCircle: {
        method: 'post',
        url: '/circle/queryUserMsgByNameNoEnterCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心---建立的圈子---发布新公告
    saveCircleNotice: {
        method: 'post',
        url: '/circle/saveCircleNotice',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
}
export {
    circledata
}