const backgradedata = {
    // 后台管理--基础数据管理--年级管理--批量删除/删除
    deleteGrade: {
        method: 'post',
        url: '/backage/grade/deleteGrade',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--年级管理查询
    backqueryGrade: {
        method: 'post',
        url: '/backage/grade/queryGrade',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--年级管理编辑查询
    queryGradeById: {
        method: 'post',
        url: '/backage/grade/queryGradeById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--年级管理--添加年级
    queryBasePeriodBackage: {
        method: 'post',
        url: '/backage/period/queryBasePeriodBackage',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 
    saveGrade: {
        method: 'post',
        url: '/backage/grade/saveGrade',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },

    // 后台管理--基础数据管理--年级管理--修改操作
    updateGrade: {
        method: 'post',
        url: '/backage/grade/updateGrade',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--年级管理--批量禁用/禁用
    updateGradeDisable: {
        method: 'post',
        url: '/backage/grade/updateGradeDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--基础数据管理--年级管理--批量启用/启用
    updateGradeEnable: {
        method: 'post',
        url: '/backage/grade/updateGradeEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },

}
export {
    backgradedata
}