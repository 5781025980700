// 基本数据
const basedata = {
    //获取学段信息
    queryPeriod: {
        method: 'post',
        url: '/base/queryPeriod',
        defaultHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    },
    //获取课程类型
    querySubjectType: {
        method: 'post',
        url: '/base/querySubjectType',
        defaultHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        },
    },
    // 获取年级信息
    queryGrade: {
        method: 'post',
        url: 'base/queryGrade',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //获取学科信息
    querySubject: {
        method: 'post',
        url: '/base/querySubject',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //获取资料类型
    queryResourceType: {
        method: 'post',
        url: '/base/queryResourceType',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //获取系列课程的标签
    querySeriseLable: {
        method: 'post',
        url: '/base/querySeriseLable',
        defaultHeaders: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    },

    // 点赞
    messagePraise: {
        method: 'post',
        url: '/message/homePage/messagePraise',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 取消点赞
    deletePraise: {
        method: 'post',
        url: '/message/homePage/deletePraise',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 收藏
    messageCollection: {
        method: 'post',
        url: '/message/homePage/messageCollection',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 取消收藏
    messageDeleteCollection: {
        method: 'post',
        url: '/message/homePage/messageDeleteCollection',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询收藏点赞情况
    queryMessageStatus: {
        method: 'post',
        url: '/message/homePage/queryMessageStatus',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
}
export {
    basedata
}