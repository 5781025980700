// 课程
const coursedata = {
    //获取课程展示列表
    queryCourselist: {
        method: 'post',
        url: 'course/homePage/queryCourselist',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    //获取系列课程
    querySeriseCourse: {
        method: 'post',
        url: '/course/homePage/querySeriseCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // ---
    //获取课程目录和课程资源
    queryCatalogue: {
        method: 'post',
        url: '/course/detail/queryCatalogue',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询课程目录下的资源的信息
    queryResourceDetail: {
        method: 'post',
        url: '/course/detail/queryResourceDetail',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 查询课程的评论信息
    queryCourseDetailComment: {
        method: 'post',
        url: '/course/detail/queryCourseDetailComment',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询评论信息
    queryCourseComment: {
        method: 'post',
        url: '/course/detail/queryCourseComment',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 课程发布评价
    releaseComment: {
        method: 'post',
        url: '/course/detail/releaseComment',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    //   ---
    // 开始学习
    startStudy: {
        method: 'post',
        url: '/course/detail/startStudy',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 结束学习
    endStudy: {
        method: 'post',
        url: '/course/detail/endStudy',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 
    //系列课程查询详情
    querySeriseCourseDetail: {
        method: 'post',
        url: '/course/detail/querySeriseCourseDetail',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 门户-系列课程-查询系列课程下的普通课程
    querySeriseCourseDetailCourse: {
        method: 'post',
        url: '/course/detail/querySeriseCourseDetailCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },



    // 查询资料
    queryResourceList: {
        method: 'post',
        url: '/course/homePage/queryResourceList',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    // -- -
    // 个人中心

    // 个人中心-课程审核-审核通过（批量）/审核不通过（批量）
    courseauditCourse: {
        method: 'post',
        url: '/courseAudit/homePage/auditCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    // 个人中心-课程审核-查询课程
    queryAuditCourse: {
        method: 'post',
        url: '/courseAudit/homePage/queryAuditCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },

    //我的课程-普通课程查询
    queryMyCourse: {
        method: 'post',
        url: '/myCourse/homePage/queryMyCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },
    //我的课程-普通课程查询
    querySeriseCourses: {
        method: 'post',
        url: '/myCourse/homePage/querySeriseCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },



    // 个人中心-系列课程-删除/批量删除系列课程
    deleteSeriseCourse: {
        method: 'post',
        url: '/serise/deleteSeriseCourse',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },

    // 个人中心-查询系列课程列表/编辑系列课程查询
    querySerise: {
        method: 'post',
        url: 'serise/querySerise',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },

    // 个人中心-建立/编辑系列课程
    saveBaseSerise: {
        method: 'post',
        url: '/serise/saveBaseSerise',
        defaultHeaders: {
            'Content-Type': "application/json"
        }
    },



    // 上传文件
    // uploadfile: {
    //     method: 'post',
    //     url: '/file/upload',
    //     defaultHeaders: {
    //         "Content-Type": 'multipart/form-data',
    //     }
    // },    
    // 多个课时上传资源
    saveCourseAndResouce: {
        method: 'post',
        url: '/personCourse/homePage/saveCourseAndResouce',
        defaultHeaders: {
            "Content-Type": 'application/json',
        }
    },
    // 个人中心-课程管理-课程上传-查询课程和资料信息/编辑课程时的查询
    queryCourselists: {
        method: 'post',
        url: '/personCourse/homePage/queryCourselist',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 删除课程、批量删除
    deleteCourse: {
        method: 'post',
        url: '/personCourse/homePage/deleteCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 个人中心-上传课程-提交审核/批量提交审核
    classupdateAudit: {
        method: 'post',
        url: '/personCourse/homePage/updateAudit',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 保存基础数据
    saveBaseCourse: {
        method: 'post',
        url: '/personCourse/homePage/saveBaseCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 创建章节、课时
    createCatalogue: {
        method: 'post',
        url: '/personCourse/homePage/createCatalogue',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 查询章节、课时
    queryCatelogueAndResource: {
        method: 'post',
        url: '/personCourse/homePage/queryCatelogueAndResource',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 编辑/删除--章节/课时
    updateCatelogue: {
        method: 'post',
        url: '/personCourse/homePage/updateCatelogue',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

    // 个人中心-上传课程--查看课时资料---删除资料
    deleteCourseCatelogueResource: {
        method: 'post',
        url: '/personCourse/homePage/deleteCourseCatelogueResource',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },



    // 完成
    receiveCourse: {
        method: 'post',
        url: '/personCourse/homePage/receiveCourse',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },


    // 课程收藏-普通课程查询
    queryMyCollectCourse: {
        method: 'post',
        url: '/myCourse/homePage/queryMyCollectCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 收藏课程-系列课程查询
    querySeriseCollectCourse: {
        method: 'post',
        url: '/myCourse/homePage/querySeriseCourse',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 资源资料收藏
    queryMyResource: {
        method: 'post',
        url: '/myCourse/homePage/queryMyResource',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈收藏
    queryMyCollectCircle: {
        method: 'post',
        url: '/myCourse/homePage/queryMyCollectCircle',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 教研圈收藏
    queryCourseDetailByID: {
        method: 'post',
        url: '/personCourse/homePage/queryCourseDetailByID',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
}
export {
    coursedata
}