const recomcircledata = {
    // 后台管理-业务管理-教研圈推荐管理--推荐教研圈---排序
    modifyLocation: {
        method: 'post',
        url: '/backage/circle/modifyLocation',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理-业务管理-教研圈推荐管理--全部教研圈---查询教研圈
    queryNoRecommendCircle: {
        method: 'post',
        url: '/backage/circle/queryNoRecommendCircle',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理-业务管理-教研圈推荐管理--推荐教研圈---查询教研圈
    queryRecommendCircle: {
        method: 'post',
        url: '/backage/circle/queryRecommendCircle',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理-业务管理-教研圈推荐管理--全部教研圈---推荐/批量推荐
    recommendCircle: {
        method: 'post',
        url: '/backage/circle/recommendCircle',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理-业务管理-教研圈推荐管理--推荐教研圈---取消推荐/批量取消推荐
    recommendCourse: {
        method: 'post',
        url: '/backage/circle/recommendNoCircle',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 

}
export {
    recomcircledata
}