// 登录
const logindata = {
    getloginUrl: {
        method: 'post',
        url: '/login/getloginUrl',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    getlogoutUrl: {
        method: 'post',
        url: 'login/getlogoutUrl',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
   
}
export {
    logindata
}