// 首页数据
const homedata = {
    // 首页---优秀课程
    queryFineCourse: {
        // 
        method: 'post',
        url: '/homePage/queryFineCourse',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 首页---热门圈子
    queryFineCircle: {
        // 
        method: 'post',
        url: '/homePage/queryFineCircle',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 首页---热门资源
    queryFineResource: {
        // 
        method: 'post',
        url: '/homePage/queryFineResource',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 首页---热门系列
    queryFineSeriseCourse: {
        // 
        method: 'post',
        url: '/homePage/queryFineSeriseCourse',
        // defaultHeaders: {
        //     "Content-Type": 'multipart/form-data',
        // }
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },
    // 首页---各种数量统计
    queryCollectCount: {
        // 
        method: 'post',
        url: '/homePage/queryCollectCount',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 首页---统计一周之内的登陆人数
    getWeekLoginNum: {
        // 
        method: 'post',
        url: '/homePage/getWeekLoginNum',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },

}
export {
    homedata
}