const backaccountdata = {
    // 后台管理--系统管理--账号管理---查询条件---查询角色
    queryuserRole: {
        method: 'post',
        url: '/backage/user/queryRole',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理--查询账号信息
    queryUser: {
        method: 'post',
        url: '/backage/user/queryUser',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理--通过用户id查询用户信息
    queryUserDetailById: {
        method: 'post',
        url: '/backage/user/queryUserDetailById',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
            // "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理--添加账号
    saveUser: {
        method: 'post',
        url: '/backage/user/saveUser',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理-绑定角色-给用户添加角色
    saveUserRole: {
        method: 'post',
        url: '/backage/user/saveUserRole',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理--修改账号
    updateUser: {
        method: 'post',
        url: '/backage/user/updateUser',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理---批量禁用
    updateUserStatusDisable: {
        method: 'post',
        url: '/backage/user/updateUserStatusDisable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--系统管理--账号管理---批量启用
    updateUserStatusEnable: {
        method: 'post',
        url: '/backage/user/updateUserStatusEnable',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },

}
export {
    backaccountdata
}