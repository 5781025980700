// 
import {
    logindata as login
} from './login/loginapi'
// 基本数据
import {
    basedata as baseinfo
} from './basedata/baseapi.js'

// 教研圈
import {
    circledata as circle
}
from './circledata/circleapi.js'

// 课程案例
import {
    coursedata as course
}
from './coursedata/courseapi.js'
// 资源资料
import {
    resourdata as resource
} from './resourcesdata/resourcesapi.js'
// 首页
import {
    homedata as home
} from './home/homedata'

// 后台管理
import {
    backroledata as backrole
} from './backsystem/backroleapi' //角色管理
import {
    backaccountdata as backaccount
} from './backsystem/backaccountapi.js' //账号管理
import {
    sensitivedata as sensitive
} from './backsystem/backsensitapi.js' //敏感词管理
import {
    backgradedata as backgrade
} from './backsystem/backgradeapi.js' //学段管理
import {
    backperioddata as backperiod
} from './backsystem/backperiodapi.js' //年级管理
import {
    backsubjectdata as backsubject
} from './backsystem/backsubjectapi.js' //学科管理
import {
    backresourdata as backresour
} from './backsystem/backresourapi.js' //资源管理
import {
    backlabeldata as backlabel
} from './backsystem/backlabelapi.js' //标签管理
import {
    recomclassdata as recomclass
} from './backrecommed/recomclassapi.js' //课程推荐管理
import {
    recomcircledata as recomcircle
} from './backrecommed/recomcircleapi.js' //教研圈推荐管理
import {
    recomresdata as recomres
} from './backrecommed/recomresapi.js' //教研圈推荐管理



// 个人中心-课程管理
// import {
//     userInfodata as coursemanage
// } from './userinfo/classController/classmanage.js'
// 个人中心--资源管理
// import {
//     resourcedata as resource
// } from './userinfo/resourceController/resourcedata'



const CONTACT_API = {
    // 登录
    ...login,
    // 基础数据--
    ...baseinfo,
    //首页
    ...home,
    // 教研圈
    ...circle,
    // 课程案例
    ...course,
    // 资源资料
    ...resource,
    // 特色课程
    // 

    // 个人中心---
    // 资源管理
    // ...resource,

    // 课程管理
    // ...coursemanage,
    // -----

    // 后台管理
    // 角色管理
    ...backrole,
    ...backaccount,
    // 基础数据管理
    ...backgrade,
    ...backperiod,
    ...backsubject,
    ...backresour,
    ...backlabel,
    ...sensitive,
    // 业务管理
    ...recomclass,
    ...recomcircle,
    ...recomres,


    // --------------我是个分界线
    // 
    // 后台管理根据用户获取所有的菜单--只限一级和二级菜单
    getBackageMenu: {
        method: 'post',
        url: '/menu/getBackageMenu',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 获取个人信息
    getUserMsg: {
        method: 'get',
        url: '/user/getUserMsg',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 获取个人中心菜单
    getAllMenu: {
        method: 'post',
        url: '/menu/getAllMenu',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 上传文件
    uploadfile: {
        method: 'post',
        url: '/file/upload',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 下载
    downLoad: {
        method: 'post',
        url: '/file/downLoad',
        defaultHeaders: {
            "Content-Type": 'multipart/form-data',
        }
    },
    // 下载资源进行统计汇总
    collect: {
        method: 'post',
        url: '/file/collect',
        defaultHeaders: {
            "Content-Type": "application/json",
        }
    },

}
export {
    CONTACT_API
}