const recomresdata = {
    // 后台管理--资源推荐管理/全部资源
    backqueryResource: {
        method: 'post',
        url: '/backage/resource/queryResource',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--资源推荐管理/推荐资源--取消推荐资源/批量取消推荐资源
    deleteRecommendResource: {
        method: 'post',
        url: '/backage/resource/deleteRecommendResource',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--资源推荐管理/推荐资源---进行排序
    modifyLocation: {
        method: 'post',
        url: '/backage/resource/modifyLocation',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    //后台管理--资源推荐管理/推荐资源--查询推荐 
    queryRecommedResource: {
        method: 'post',
        url: '/backage/resource/queryRecommedResource',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 后台管理--资源推荐管理/全部资源---推荐课程/批量推荐
    recommendCourse: {
        method: 'post',
        url: '/backage/resource/recommendCourse',
        defaultHeaders: {
            // "Content-Type": 'multipart/form-data',
            "Content-Type": "application/json",
        }
    },
    // 

}
export {
    recomresdata
}